body {
  .desktop-py-sidenav {
    background: #6b5eb0;
    position: fixed;
    top: 45px;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 77px;
    text-align: center;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    nav {
      height: 446px;
      width: 100%;
      margin-top: 20px;
      overflow: auto;

      ul {
        padding: 0 4px 0 3px;

        li {
          position: relative;
          padding: 5px 0;

          a {
            display: block;
            width: 100%;
            margin: 0 3px 0 auto;
            padding: 10px 0px 6px 0px;
            border-radius: 4px;

            &.active,
            &:hover {
              background: rgba(0, 0, 0, 0.2);
            }

            img {
              // width: 20px;
              margin-right: 1px;
            }

            p {
              font-family: $ibmplexRegular;
              font-size: 12px;
              margin-top: 5px;
              color: #fff;
              display: block;
              position: relative;
              line-height: 11px;
              left: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .notification-menu-list {
    li.list-inline-item {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }

      img {
        width: 20px;
      }
    }
  }

  .desktop-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0;
    border-bottom: 1px solid #d7d7d7;
    // background: #f4f1f1;
    background: $whiteColor;
    z-index: 999;
    // box-shadow: 0 -1px 20px -3px rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: center;

    .app-switcher {
      @include elementStyles(
        $size: $font16,
        $color: #bd10e0,
        $fontFamily: $ibmplexMedium
      );
    }

    .MuiSvgIcon-fontSizeSmall {
      font-size: 20px;
    }

    .app-logo-search {
      .app-logo {
        a {
          padding: 0 22px 0 17px;

          img {
            width: 34px;
          }
        }
      }

      .search-form {
        width: 80%;

        form {
          input {
            width: 100%;
            height: 52px;
            border: 0;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            background: #f3f6f9;
            padding-left: 20px;
            font-size: 18px;
            font-family: $ibmplexLight;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    .header-right-nav {
      float: right;

      > .list-inline-item {
        margin-left: 1px;
        padding: 0px 9px;
        height: 45px;
        display: flex;
        align-items: center;

        &:first-child {
          margin-left: 0;
          border-left: none;
        }

        &:last-child {
          border-left: 1px solid #ddd;
        }

        &#lessor-dropdown {
          .MuiInput-underline:before,
          .MuiInput-underline:after {
            border: none;
          }
          .MuiInput-input {
            font-size: $font14;
          }
        }

        .user-menu-dropdown {
          .MuiButton-root {
            &::after {
              content: "";
              color: transparent;
              font-size: 0;
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #5d56a3;
              border-radius: 3px;
              margin-left: 8px;
            }
          }

          button {
            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }

            h3 {
              font-size: 14px;
              line-height: 12px;
              text-transform: none;
            }

            span {
              font-size: 11px;
            }
          }
        }
      }

      .count-symbol {
        position: absolute;
      }

      .count-symbol {
        top: 20px;
        right: 13px;
        width: 15px;
        height: 15px;
        background-color: $redColor;
        border-radius: 100%;

        .count-number {
          font-size: 8px;
          color: $whiteColor;
        }
      }
    }

    .mui-menu {
      .btn-primary {
        font-size: $font14;
      }

      .MuiButton-label {
        font-weight: 600;
        text-transform: none;
      }
    }
  }

  .menu-items {
    color: #000000;
  }

  .mobile-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #d7d7d7;
    z-index: 100;

    ul {
      float: right;

      li {
        margin-left: 16px;

        a {
          img {
            width: 16px;
          }
        }
      }
    }
  }

  .mobile-main-navs {
    width: 250px;
    // padding: 10px;
    background: linear-gradient(0deg, #7c52e9, #2c92ff);
    height: 100%;
    overflow: auto;

    .user-info {
      padding: 10px;
      border-radius: 0;

      // margin-bottom: 10px;
      .user-card {
        .user-avatar {
          float: left;
          margin-right: 4px;
        }

        h3 {
          @include elementStyles(
            $size: $font16,
            $color: #000000,
            $fontFamily: $ibmplexRegular
          );
        }

        span {
          @include elementStyles(
            $size: $font13,
            $color: #000000,
            $fontFamily: $ibmplexRegular
          );
        }
      }
    }

    .mobile-py-link {
      padding: 10px;
      background: transparent;

      ul {
        li {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              width: 20px;
              margin-right: 10px;
            }

            p {
              display: inline;
              @include elementStyles(
                $size: $font14,
                $color: #fff,
                $fontFamily: $ibmplexRegular
              );
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  .pr-0 {
    padding-right: 0 !important;
  }

  .secondary-sidenav-bg {
    position: fixed;
    left: 77px;
    height: 100%;
    top: 45px;
    z-index: 99;
    background: rgba(113, 99, 186, 0.9);

    .sidebar-icon {
      margin: 10px 6px 10px 5px;

      .menu-button {
        padding: 5px 6px 3px 6px !important;
        background: rgba(113, 99, 186, 0.9);
        border: none;
      }
    }

    .secondary-sidenav {
      // width: 182px;
      padding: 10px 6px 6px;
      overflow: auto;
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
      // background: rgba(113, 99, 186, 0.9);
      height: 100%;

      &::-webkit-scrollbar {
        width: 0 !important;
      }

      h4 {
        padding: 10px 15px;
        margin: 8px 0;
        @include elementStyles(
          $size: $font16,
          $color: #f0a4ff,
          $fontFamily: $ibmplexRegular
        );
      }

      ul {
        // margin-top: 20px;
        li {
          a {
            display: block;
            padding-left: 32px;
            cursor: pointer;
            font-size: $font14 !important;
            padding: 10px;
            @include elementStyles(
              $size: $font14,
              $color: #fff,
              $fontFamily: $ibmplexRegular
            );

            &.active {
              background: $whiteColor;
              color: #000000;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  .mobile-secondary-drawer {
    padding: 5px 15px 2px 15px;
    background-image: linear-gradient(-180deg, #6538db, #0a56a8);
    color: #fff;

    svg {
      cursor: pointer;
    }
  }

  .mobile-secondary-manulist {
    h4 {
      padding: 10px 15px;
      font-size: 16px;
      color: #f0a4ff;
      margin: 8px 0;
    }

    ul {
      li {
        a {
          display: block;
          padding-left: 32px;
          cursor: pointer;
          font-size: 12px;
          color: #fff;
          padding: 10px 15px;

          &:hover,
          &.active {
            background-image: linear-gradient(
              90deg,
              hsla(0, 0%, 100%, 0.5) 0,
              hsla(0, 0%, 100%, 0)
            );
          }
        }
      }
    }
  }

  .engine-sv-card {
    background: #fff;
    border: 1px solid #c6cddd;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    border-radius: 3px;
    margin-bottom: 15px;

    .tab-content {
      padding: 0 15px 15px;

      .text-label {
        font-family: $ibmplexRegular;
        font-size: 12px;
        color: #a0a0a0;
        display: inline-block;
        line-height: 16px;
      }

      .para-ui {
        font-family: $ibmplexRegular;
        font-size: 14px;
        color: #000;
      }
    }
  }

  footer {
    background: #191c26;
    padding: 50px 0;

    h4 {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      margin: 0 0 15px 0;
      clear: both;
    }

    a {
      font-size: 13px;
      color: #fff !important;
      font-weight: 400;
      display: inline-block;
    }

    ul.list-unstyled {
      li {
        a {
          color: $whiteColor;
          padding: 8px 0;
          color: hsla(0, 0%, 100%, 0.8) !important;
        }
      }
    }

    ul.list-inline {
      margin-bottom: 15px;
      display: inline-block;

      li {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .footer-copyright {
      margin-top: 20px;

      img {
        width: 120px;
        margin-bottom: 10px;
      }

      p {
        font-size: 13px;
        font-weight: 300;
        color: #fff;
      }
    }
  }
}

.sideOpen {
  margin: 45px 0px 0px -10px;
  padding: 10px 10px 50px 20px !important;
  background: white !important;
}

.sideClosed {
  margin: 45px 0px 0px 136px;
  padding: 0px 10px 50px !important;
}

@media (max-width: 1800px) {
  .sideOpen {
    margin: 45px 0px 0px 0px;
    padding: 3px 10px 50px 20px !important;
  }
}

@media (max-width: 1700px) {
  .sideOpen {
    margin: 45px 0px 0px 18px;
    padding: 3px 10px 50px 20px !important;
  }
}

@media (max-width: 1600px) {
  .sideOpen {
    // margin: 99px 0px 0px 42px;
    margin: 45px 0px 0px 47px;
    padding: 3px 15px 70px 15px !important;
  }
}

@media (max-width: 1500px) {
  .sideOpen {
    margin: 45px 0px 0px 53px;
    padding: 3px 20px 50px !important;
  }
}

@media (max-width: 1400px) {
  .sideOpen {
    margin: 45px 0px 0px 68px;
    padding: 3px 20px 50px !important;
  }
}

@media (max-width: 1300px) {
  .sideOpen {
    margin: 45px 0px 0px 84px;
    padding: 3px 20px 50px !important;
  }
}

@media (min-width: 1200px) {
  .sideClosed {
    // margin: 76px 0px 0px 136px;
    margin: 45px 0px 0px 124px;
  }
}

.w-100 {
  width: 100%;
}
