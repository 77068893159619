$details_padding: 9px 0;

#dashboard {
  .app-card {
    background-color: #9d9b96;
    padding: 20px;
    box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
    border-radius: 4px;
    color: $whiteColor;
    min-height: 225px;
    width: 445px;
    .app-card-header {
      display: flex;
      h3 {
        padding-left: 10px;
      }
    }
  }
}
